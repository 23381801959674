import React from 'react'
import get from 'lodash/get'
import { Link, graphql } from 'gatsby'
import Layout from "../components/layout"
import { rhythm } from '../utils/typography'

class TagPageTemplate extends React.Component {
    render() {
        const currentTag = get(this.props, 'pageContext.tag')
        const siteTitle = get(this.props, 'data.site.siteMetadata.title')
        const posts = get(this, 'props.data.posts.edges')
        const htmlPosts = posts.reduce((acc, post) => {
            return [
                ...acc,
                <li
                    key={post.node.frontmatter.title}
                    style={{
                        marginBottom: rhythm(1 / 4),
                    }}
                >
                    <p>
                        <Link
                            style={{ boxShadow: 'none' }}
                            to={post.node.frontmatter.path}
                        >
                            {post.node.frontmatter.title}
                        </Link>&nbsp;---&nbsp;
                        <span
                            dangerouslySetInnerHTML={{
                                __html: post.node.excerpt,
                            }}
                            style={{ p: { display: 'inline' } }}
                        />
                    </p>
                </li>,
            ]
        }, [])

        return (
			<Layout location={this.props.location} title={siteTitle}>
			<h1>{currentTag}</h1>
                <div>
                    <h2>Derniers articles</h2>
                    <ul>{htmlPosts}</ul>
                </div>
			</Layout>
        )
    }
}

export default TagPageTemplate

export const pageQuery = graphql`
    query BlogPostByTag($tag: String!) {
        site {
            siteMetadata {
                title
                author
            }
        }
        posts: allMarkdownRemark(
            filter: {
                frontmatter: { draft: { ne: true }, tags: { in: [$tag] } }
            }
            sort: { fields: [frontmatter___date], order: DESC }
        ) {
            edges {
                node {
                    id
                    excerpt(pruneLength: 300)
                    frontmatter {
                        title
                        path
                        date(locale: "fr-FR", formatString: "DD/MM/YYYY")
                    }
                }
            }
        }
    }
`
